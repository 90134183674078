import { createSelector, createSlice } from '@reduxjs/toolkit'
import { normalize, denormalize } from 'normalizr'
// import defaultsDeep from 'lodash/defaultsDeep'
import merge from 'lodash/merge'
import { v4 as uuidv4 } from 'uuid'

import { orderSchema, ordersForChefDataSchema, restaurantsDataSchema, subscriptionSchema } from 'schemas/restaurant'

import { Subscription } from 'data/subscription'
import { RestaurantEntityType, ScheduleType } from 'data/restaurants'
import { BrandEntityType } from 'data/brands'
import { SiteType } from 'data/sites'
import { MenuCategoryEntityType } from 'data/category'
import { MenuItemEntityType, MenuItemOption, MenuItemOptionGroup } from 'data/menuItem'
import { OrderEntityType } from 'data/order'

interface ApiMethod {
  status?: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface ApiState {
  entities: {
    orders?: { [key: string]: OrderEntityType }
    restaurants?: { [key: string]: RestaurantEntityType }
    brands?: { [key: string]: BrandEntityType }
    sites?: { [key: string]: SiteType }
    schedules?: { [key: string]: ScheduleType }
    menuCategories?: { [key: string]: MenuCategoryEntityType }
    menuItems?: { [key: string]: MenuItemEntityType }
    menuItemOptions?: { [key: string]: MenuItemOption }
    menuItemOptionGroups?: { [key: string]: MenuItemOptionGroup }
  },
  getRestaurants: any & ApiMethod
  getChefOrders: any & ApiMethod
}

export const initialState: ApiState = {
  entities: {},
  getRestaurants: {
    status: 'idle',
    error: null,
    data: [],
    count: 0
  },
  getChefOrders: {
    status: 'idle',
  },
}

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    subscriptionOnMessage(state, action) {
      const { menuAvailabilityChanged, ...data } = (action.payload?.data || {}) as Subscription
      const transformedData = { ...data, menuAvailabilityChanged: { items: [], options: [], categories: [] } }
      if (menuAvailabilityChanged?.itemsToEnable?.length) {
        menuAvailabilityChanged?.itemsToEnable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.items.push({ id, disabled: false })
        })
      }
      if (menuAvailabilityChanged?.itemsToDisable?.length) {
        menuAvailabilityChanged?.itemsToDisable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.items.push({ id, disabled: true })
        })
      }
      if (menuAvailabilityChanged?.optionsToEnable?.length) {
        menuAvailabilityChanged?.optionsToEnable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.options.push({ id, disabled: false })
        })
      }
      if (menuAvailabilityChanged?.optionsToDisable?.length) {
        menuAvailabilityChanged?.optionsToDisable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.options.push({ id, disabled: true })
        })
      }
      if (menuAvailabilityChanged?.categoriesToEnable?.length) {
        menuAvailabilityChanged?.categoriesToEnable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.categories.push({ id, disabled: false })
        })
      }
      if (menuAvailabilityChanged?.categoriesToDisable?.length) {
        menuAvailabilityChanged?.categoriesToDisable.forEach((id) => {
          // @ts-ignore
          transformedData.menuAvailabilityChanged.categories.push({ id, disabled: true })
        })
      }
      if (transformedData.restaurantScheduleChanged?.restaurantId) {
        // @ts-ignore
        transformedData.restaurantScheduleChanged.schedules = data.restaurantScheduleChanged?.schedules.map((schedule) => ({
          ...schedule,
          id: schedule.id || uuidv4(),
        }))
      }
      // @ts-ignore
      const normalizedData = normalize(transformedData, subscriptionSchema)
      /*
      if (normalizedData.result.orderChanged?.order) {
        const orderId = normalizedData.result.orderChanged.order
        // @ts-ignore
        const newStatus = normalizedData.entities.orders[orderId]?.status
        // @ts-ignore
        const prevStatus = state.entities.orders?.[orderId]?.status
        // @ts-ignore
        if (!state.getChefOrders[newStatus]?.data?.find((id: string) => orderId === id)) {
          if (!state.getChefOrders[newStatus]?.data) {
            state.getChefOrders[newStatus] = {
              data: [],
              count: 0,
            }
          }
          state.getChefOrders[newStatus].data.push(orderId)
          state.getChefOrders[newStatus].count += 1
          state.getChefOrders.status = 'succeeded'
        }
        if (prevStatus && prevStatus !== newStatus) {
          const prevIndex = state.getChefOrders[prevStatus]?.data?.findIndex((id: string) => orderId === id)
          // @ts-ignore
          if (prevIndex > -1) {
            state.getChefOrders[prevStatus].data.splice(prevIndex, 1)
            state.getChefOrders[prevStatus].count -= 1
            state.getChefOrders.status = 'succeeded'
          }
        }
      }
      */
      if (normalizedData.result.restaurantScheduleChanged?.restaurantId && state.entities.restaurants) {
        state.entities.restaurants[normalizedData.result.restaurantScheduleChanged.restaurantId] = {
          ...state.entities.restaurants[normalizedData.result.restaurantScheduleChanged.restaurantId] || {},
          id: normalizedData.result.restaurantScheduleChanged.restaurantId,
          // @ts-ignore
          schedule: normalizedData.result.restaurantScheduleChanged.schedules
        }
      }
      merge(state.entities, normalizedData.entities)
    },
    fetchOrdersLoading(state) {
      state.getChefOrders.status = 'loading'
    },
    fetchOrdersError(state) {
      if (state.getChefOrders) {
        state.getChefOrders.status = 'failed'
      } else {
        state.getChefOrders = { status: 'failed' }
      }
    },
    fetchOrdersSuccess(state, action) {
      const normalizedData = normalize(action.payload, ordersForChefDataSchema)
      state.getChefOrders = {
        ...normalizedData.result,
        status: 'succeeded'
      }
      // merge(state.entities, normalizedData.entities)
      // merge(state.entities.orders, normalizedData.entities.orders)
      state.entities.orders = {
        ...(state.entities.orders || {}),
        ...(normalizedData.entities.orders || {}),
      }
      state.entities.restaurants = merge({}, state.entities.restaurants || {}, normalizedData.entities.restaurants || {})
      state.entities.brands = merge({}, state.entities.brands || {}, normalizedData.entities.brands || {})
      state.entities.sites = merge({}, state.entities.sites || {}, normalizedData.entities.sites || {})
    },
    fetchRestaurantsLoading(state) {
      state.getRestaurants.status = 'loading'
    },
    fetchRestaurantsError(state) {
      if (state.getRestaurants) {
        state.getRestaurants.status = 'failed'
      } else {
        state.getRestaurants = { status: 'failed' }
      }
    },
    fetchRestaurantsSuccess(state, action) {
      const normalizedData = normalize(action.payload.restaurants, restaurantsDataSchema)
      // console.log('fetchRestaurantsAsync=', normalizedData)

      // with higher priority for merging
      state.entities.restaurants = {
        ...(state.entities.restaurants || {}),
        ...(normalizedData.entities.restaurants || {}),
      }
      state.entities.brands = {
        ...(state.entities.brands || {}),
        ...(normalizedData.entities.brands || {}),
      }
      state.entities.sites = {
        ...(state.entities.sites || {}),
        ...(normalizedData.entities.sites || {}),
      }
      state.entities.schedules = normalizedData.entities.schedules || {}
      state.entities.menuCategories = normalizedData.entities.menuCategories || {}
      state.entities.menuItems = normalizedData.entities.menuItems || {}
      state.entities.menuItemOptions = normalizedData.entities.menuItemOptions || {}
      state.entities.menuItemOptionGroups = normalizedData.entities.menuItemOptionGroups || {}
      state.getRestaurants = {
        ...normalizedData.result,
        status: 'succeeded'
      }
    },
  },
})

export const { subscriptionOnMessage, fetchOrdersSuccess, fetchOrdersError, fetchOrdersLoading, fetchRestaurantsSuccess, fetchRestaurantsError, fetchRestaurantsLoading } = apiSlice.actions

export const selectOrdersData = (state: { api: ApiState }) => state.api.getChefOrders || {}
export const selectOrdersDataStatus = (state: { api: ApiState }) => state.api.getChefOrders?.status
export const selectEntitiesOrders = (state: { api: ApiState }) => state.api.entities?.orders || {}
export const selectRestaurantsList = (state: { api: ApiState }) => state.api.getRestaurants?.data || {}
export const selectEntitiesRestaurants = (state: { api: ApiState }) => state.api.entities?.restaurants || {}
export const selectEntitiesBrands = (state: { api: ApiState }) => state.api.entities?.brands || {}
export const selectEntitiesSites = (state: { api: ApiState }) => state.api.entities?.sites || {}
export const selectEntitiesSchedules = (state: { api: ApiState }) => state.api.entities?.schedules || {}

export const selectBrandNamesByRestaurants = createSelector(
  selectEntitiesRestaurants,
  restaurants =>
    Object.keys(restaurants || []).reduce(
      (acc, restaurantId: string) => ({
        ...acc,
        [restaurantId]: restaurants[restaurantId]?.brandName
      }),
      {}
    )
)
export const selectOrderById = createSelector(
  [selectEntitiesOrders, (state, orderId) => orderId],
  (orders, id) => orders[id]
)
export const selectRestaurantById = createSelector(
  [selectEntitiesRestaurants, (state, restaurantId) => restaurantId],
  (restaurants, id) => restaurants[id]
)
export const selectBrandById = createSelector(
  [selectEntitiesBrands, (state, brandId) => brandId],
  (brands, id) => brands[id]
)
export const selectSiteById = createSelector(
  [selectEntitiesSites, (state, siteId) => siteId],
  (sites, id) => sites[id]
)
export const selectScheduleById = createSelector(
  [selectEntitiesSchedules, (state, scheduleId) => scheduleId],
  (schedules, id) => schedules[id]
)
export const selectScheduleByIds = createSelector(
  [selectEntitiesSchedules, (state, scheduleIds) => scheduleIds],
  (schedules, ids) => ids?.map((id: string) => schedules[id]) || []
)
export const selectDenormalizedOrderById = createSelector(
  [selectEntitiesOrders, selectEntitiesSites, selectEntitiesBrands, selectEntitiesRestaurants, (_, orderId) => orderId],
  (orders, sites, brands, restaurants, id) => {
    const order = denormalize(orders[id], orderSchema, { sites, brands, restaurants })
    // console.log('denormalizedOrderById=', order)

    if (!order) {
      return {}
    }

    const result = {
      ...order,
      boughtMenuItems: order.boughtMenuItems?.map((item: any) => ({
        ...item,
        brandName: restaurants[item.restaurantId !== 'default' ? item.restaurantId : order.restaurantId]?.brandName || item.restaurantId,
      })),
    }
    return result
  }
)

export default apiSlice.reducer
