import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import moment from 'moment-timezone'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

import { loginTokenKey, restoreAuth } from 'services/auth'
import { request, subscription } from 'slices/ui'
import {
  fetchOrdersError,
  fetchOrdersLoading,
  fetchOrdersSuccess,
  fetchRestaurantsError, fetchRestaurantsLoading, fetchRestaurantsSuccess,
  subscriptionOnMessage
} from 'slices/api'

import packageInfo from '../../package.json'

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'

type ClientProps = {
  token?: string
  setError?: (message: string) => void
  dispatch?: (data: any) => void
  onReconnect?: () => void
}

export const getApolloClient = ({ token, setError, dispatch, onReconnect }: ClientProps) => {
  const httpLink = createHttpLink({
    uri: `${apiUrl}/graphql`,
  })

  const logLink = new ApolloLink((operation, forward) => {
    const start = Date.now()
    console.log(`${new Date().toISOString().slice(-13)} 📡 Sending ${operation.operationName} request`)
    if (operation.operationName === 'getOrders') {
      dispatch?.(fetchOrdersLoading())
    }
    if (operation.operationName === 'getRestaurants') {
      dispatch?.(fetchRestaurantsLoading())
    }
    return forward(operation).map(result => {
      console.log(`${new Date().toISOString().slice(-13)} 📡 Received ${operation.operationName} response in ${Date.now() - start}ms`)
      // console.log(`${new Date().toISOString().slice(-13)} 📡 Received ${operation.operationName} response`, result)
      // if (process.env.REACT_APP_ENV === 'production') {
      //   console.log('📡 Response = ', JSON.stringify(result))
      // }
      if (operation.operationName === 'getOrders') {
        if (result?.data) {
          dispatch?.(fetchOrdersSuccess(result.data))
        } else {
          dispatch?.(fetchOrdersError())
        }
      }
      if (operation.operationName === 'getRestaurants') {
        if (result?.data) {
          dispatch?.(fetchRestaurantsSuccess(result.data))
        } else {
          dispatch?.(fetchRestaurantsError())
        }
      }
      return result
    })
  })

  const authLink = setContext(async ({ operationName, variables }, { headers }) => {
    const authToken = token || localStorage.getItem(loginTokenKey)
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
        'X-OME-Client': process.env.REACT_APP_IS_EMBED ? 'woc' : packageInfo.name,
        'X-OME-Client-Version': packageInfo.version,
        'X-OME-Timezone': moment.tz.guess(),
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error: any) => {
        console.log('[GraphQL error]:', error)

        if (setError) {
          if (error?.statusCode === 403) {
            setError(
              'Your login and password are incorrect. Try another time or restore the password'
            )
          } else if (error?.message) {
            setError(typeof error.message === 'string' || error.message instanceof String ? error.message : JSON.stringify(error.message))
          } else {
            setError('[GraphQL error]: See logs for details')
          }
        }
      })

      // @ts-ignore
      if (graphQLErrors.find(error => error?.code === 'UNAUTHENTICATED')) {
        setTimeout(() => {
          restoreAuth()
        }, 1000)
      }
    }
    if (networkError) {
      console.log('[Network error]:', networkError)
      if (setError) {
        setError('[Network error]: See log for details')
      }
    }
  })

  let activeSocket: any = null
  let timedOut: any = null

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${(process.env.REACT_APP_API_URL as string).replace(/^http/, 'ws')}/graphql`,
      connectionParams: () => {
        const authTokenString = token || localStorage.getItem(loginTokenKey)
        return ({
          Authorization: authTokenString ? `Bearer ${authTokenString}` : ''
        })
      },
      retryAttempts: Infinity,
      shouldRetry: () => true,
      keepAlive: 10000,
      on: {
        message: ({ type, payload }: any) => {
          if (type !== 'next') return
          dispatch?.(subscriptionOnMessage(payload))
          console.log('WS message = ', process.env.REACT_APP_ENV === 'production' ? JSON.stringify(payload) : payload)
        },
        closed: () => {
          console.log('WS closed')
        },
        connected: (socket) => {
          activeSocket = socket
          console.log('WS connected')
          onReconnect?.()
        },
        ping: (received) => {
          if (!received) // sent
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN)
                activeSocket.close(4408, 'Request Timeout')
            }, 5_000) // wait 5 seconds for the pong and then close the connection
        },
        pong: (received) => {
          if (received) clearTimeout(timedOut) // pong is received, clear connection close timeout
        },
      },
    })
  )

  const splitLink = split(
    ({ query, operationName, variables, ...rest }) => {
      const definition = getMainDefinition(query)
      if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
        dispatch?.(subscription({ operationName, variables }))
      } else {
        dispatch?.(request({ operationName, variables }))
      }
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink, // Use WebSocket link for subscriptions
    httpLink // Use HTTP link for queries and mutations
  )

  return new ApolloClient({
    link: from([logLink, authLink, errorLink, splitLink]),
    cache: new InMemoryCache(),
    resolvers: {
      Query: {
        getEmptyData() {
          return {}
        }
      },
      Mutation: {
        submitData(root: any, { data }: any) {
          return data
        }
      }
    }
  })
}
