import { schema } from 'normalizr'

import { OrderStatus } from 'data/order'

export const convertStringDateToNumber = (date: string) => {
  try {
    if (!date) return null
    return new Date(date).getTime().toString()
  } catch (e) {
    return null
  }
}

export const menuItemOptionSchema = new schema.Entity(
  'menuItemOptions',
  {},
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const menuItemOptionGroupSchema = new schema.Entity(
  'menuItemOptionGroups',
  {},
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const menuItemSchema = new schema.Entity(
  'menuItems',
  {
    menuItemOptions: [menuItemOptionSchema],
    menuItemOptionGroup: [menuItemOptionGroupSchema]
  },
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const menuCategorySchema = new schema.Entity(
  'menuCategories',
  {
    menuItems: [menuItemSchema]
  },
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const brandSchema = new schema.Entity(
  'brands',
  {
    menuCategories: [menuCategorySchema]
  },
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const siteSchema = new schema.Entity(
  'sites',
  {},
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const scheduleSchema = new schema.Entity(
  'schedules',
  {},
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const restaurantSchema = new schema.Entity(
  'restaurants',
  {
    brand: brandSchema,
    site: siteSchema,
    // TODO: operator?
    schedule: [scheduleSchema]
  },
  {
    processStrategy: ({ __typename, brandId, siteId, operator, ...value }, parent, key) => {
      const brandName = value.brand?.name
      const siteName = value.site?.name
      return { ...value, brandName, siteName }
    }
  }
)

export const orderSchema = new schema.Entity(
  'orders',
  {
    restaurant: restaurantSchema
  },
  {
    processStrategy: ({ __typename, ...value }, parent, key) => {
      return value
    }
  }
)

export const orderChangedSchema = new schema.Entity(
  'orders',
  {
    restaurant: restaurantSchema
  },
  {
    processStrategy: ({ __typename, ...value }) => ({
      ...value,
      createdAt: convertStringDateToNumber(value.createdAt),
      updatedAt: convertStringDateToNumber(value.updatedAt),
      estimatedReadinessDate: convertStringDateToNumber(value.estimatedReadinessDate),
      platformExpectedPickupTime: convertStringDateToNumber(value.platformExpectedPickupTime),
      startPreparingDate: convertStringDateToNumber(value.startPreparingDate),
      paidDate: convertStringDateToNumber(value.paidDate),
      preparedDate: convertStringDateToNumber(value.preparedDate),
      pickupDate: convertStringDateToNumber(value.pickupDate),
      completedDate: convertStringDateToNumber(value.completedDate),
    })
  }
)

export const restaurantsDataSchema = { data: [restaurantSchema] }

export const ordersForChefDataSchema = {
  [OrderStatus.NEW]: { data: [orderSchema] },
  [OrderStatus.PREPARING]: { data: [orderSchema] },
  [OrderStatus.AWAITING_PICKUP]: { data: [orderSchema] },
  [OrderStatus.PICKED_UP]: { data: [orderSchema] }
}

export const subscriptionSchema = {
  restaurantChanged: restaurantSchema,
  /*
  orderChanged: {
    order: orderChangedSchema
  },
  */
  restaurantScheduleChanged: {
    schedules: [scheduleSchema],
  },
  menuAvailabilityChanged: {
    items: [menuItemSchema],
    options: [menuItemOptionSchema],
    categories: [menuCategorySchema],
  },
  menuChanged: {
    menuCategory: menuCategorySchema,
    menuItem: menuItemSchema,
    menuItemOption: menuItemOptionSchema,
    menuItemOptionGroup: menuItemOptionGroupSchema,
  },
}
