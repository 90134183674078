import { gql } from '@apollo/client'

export const getMeQuery = gql`
  query getMe {
    me {
      id
      operatorId
      email
      firstName
      lastName
      name
      phone
      role
      status
      sites {
        stripeLocationId
      }
      languageCode
    }
  }
`

export const exchangeRefreshToken = gql`
  mutation ExchangeRefreshMutation($data: ExchangeRefreshTokenInput!) {
    exchangeRefreshToken(data: $data) {
      jwtToken
      refreshToken
    }
  }
`

export const loginAction = gql`
  mutation SigninMutation($data: SigninInput!) {
    signin(signinInput: $data) {
      jwtToken
      refreshToken
      status
    }
  }
`

export const signupAction = gql`
  mutation SignupMutation($data: SignupInput!) {
    signup(signupInput: $data) {
      jwtToken
      refreshToken
      status
    }
  }
`

export const forgotPasswordAction = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      token
      userId
    }
  }
`

export const resetPasswordAction = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      jwtToken
      refreshToken
    }
  }
`

export const changePasswordAction = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      id
    }
  }
`

export const setPasswordAction = gql`
  mutation setPassword($data: SetPasswordInput!) {
    setPassword(data: $data) {
      id
    }
  }
`
