import { red, deepOrange } from '@mui/material/colors'
import { adaptV4Theme, createTheme, alpha } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

export const COLORS = {
  PRIMARY: '#34aae4',
  SECONDARY: '#2b3486'
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

// A custom theme for this app
const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // additional
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: COLORS.PRIMARY,
      // second: COLORS.SECONDARY,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff'
    },
    secondary: deepOrange,
    // TODO: fix ts
    // @ts-ignore
    primaryAlt: {
      main: COLORS.SECONDARY,
    },
    neutral: {
      main: grey[100],
      dark: grey[300]
    },
    error: red,
    background: {
      default: process.env.REACT_APP_IS_EMBED === 'true' ? 'unset' : '#fff'
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        overflowY: 'visible'
      }
    },
    MuiDialogContent: {
      root: {
        overflowY: 'visible'
      }
    }
  }
}))

export default createTheme(theme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'neutral' },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300])
          }
        },
        {
          props: { variant: 'outlined', color: 'neutral' },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`
            },
            '&:hover': {
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: 'neutral', variant: 'text' },
          style: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  }
})
