import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { es, pt } from 'yup-locales'
import * as yup from 'yup'
import locale from 'yup/lib/locale'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENV === 'development',
    defaultNS: 'main',
    fallbackLng: 'en',
  })

i18n.on('languageChanged', (lang) => {
  if (lang?.toLowerCase() === 'es') {
    yup.setLocale(es)
  } else if (lang?.toLowerCase() === 'pt') {
    yup.setLocale(pt)
  } else {
    yup.setLocale(locale)
  }
})

export default i18n
