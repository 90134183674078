import { /*createAsyncThunk,*/ createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface UIState {
  brandMenu: {
    isChanged: boolean
  },
  printerStatus: number
}
/*
export const requestAsync = createAsyncThunk('graphql/request', async (data: any) => {
 console.log('requestAsync=', data)
})
*/

const initialState: UIState = {
  brandMenu: {
    isChanged: false
  },
  printerStatus: 0,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // TODO: refactor to external network action
    request(state, { payload }) {
      // console.log('graphQLRequest=', payload)
      const { operationName }: { operation?: string; operationName: string; variables: any; } = payload
      if (
        [
          'createMenuCategory',
          'createMenuCategories',
          'updateMenuCategory',
          'removeMenuCategory',
          'updateMenuCategories',
          'saveMenuCategoryVat',
          'createMenuItem',
          'createMenuItems',
          'updateMenuItem',
          'updateMenuItems',
          'removeMenuItem',
          'createMenuItemOptionGroup',
          'saveMenuItemOptionGroup',
          'saveMenuItemOptionGroupWithOptions',
          'updateMenuItemGroups',
          'removeMenuItemGroup',
          'createMenuItemOption',
          'saveMenuItemOption',
          'updateMenuItemOptions',
          'removeMenuItemOption'
        ].indexOf(operationName) > -1
      ) {
        state.brandMenu = { ...(state.brandMenu || {}), isChanged: true }
      }
    },
    // tslint:disable-next-line:no-empty
    subscription(state, { payload }) {},
    setBrandMenuIsChanged(state, { payload: { isChanged } }) {
      state.brandMenu = { ...(state.brandMenu || {}), isChanged }
    },
    setPrinterStatus(state, { payload: status }) {
      state.printerStatus = status
    },
  }
  /* TODO: add async method
  extraReducers: builder => {
    builder.addCase(requestAsync.fulfilled, (state, action) => {
      console.log('[ACTION]: ', action)
    })
  },
  */
})

export const { request, subscription, setBrandMenuIsChanged, setPrinterStatus } = uiSlice.actions

export const selectBrandMenu = (state: RootState) => state.ui.brandMenu.isChanged || false
export const selectPrinterStatus = (state: RootState) => state.ui.printerStatus || 0

export default uiSlice.reducer
