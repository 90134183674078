import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import { RootState } from 'app/store'
import { getApolloClient } from 'api/apollo'
import { getMeQuery } from 'api/auth'
import { setLocation, setToken } from 'services/auth'

import i18n from 'lib/i18n'
import { LanguageCode } from 'data/locales'

export interface UserState {
  token?: string
  me?: {
    id: string
    operatorId?: string
    email?: string
    firstName?: string
    lastName?: string
    name?: string
    phone?: string
    role: string
    status: string
    sites: [{
      stripeLocationId?: string
    }],
    languageCode: LanguageCode
  }
  status: 'idle' | 'loading' | 'success' | 'failed'
}

const initialState: UserState = {
  status: 'idle'
}

export const getMeInfo = ({ token }: any) => {
  return getApolloClient({ token }).query({ query: getMeQuery })
}

export const getUserInfoAsync = createAsyncThunk('user/me', async (token: string) => {
  const response = await getMeInfo({ token })

  setLocation(response?.data?.me?.sites[0]?.stripeLocationId)
  setToken(token)
  // console.log('response?.data?.me?.languageCode=', response?.data?.me?.languageCode)
  // console.log('i18n.language=', i18n.language)
  await i18n.changeLanguage(response?.data?.me?.languageCode.toLowerCase())

  return response.data
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    init(state) {
      state.status = 'idle'
    },
    login(state, { payload }) {
      state.token = payload
    },
    logout: state => ({ status: 'idle' }),
  },
  extraReducers: builder => {
    builder
      .addCase(getUserInfoAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.me = action.payload?.me
        state.status = 'success'
      })
      .addCase(getUserInfoAsync.rejected, state => {
        state.status = 'failed'
      })
      .addCase(REHYDRATE, (state, action) => {
        // @ts-ignore
        setLocation(action?.payload?.user.me?.sites[0]?.stripeLocationId)
      })
  }
})

export const { init, login, logout } = userSlice.actions
export const selectUserStatus = (state: RootState) => state.user.status
export const selectUserId = (state: RootState) => state.user.me?.id
export const selectUserName = (state: RootState) => state.user.me?.name
export const selectUserEmail = (state: RootState) => state.user.me?.email
export const selectUserRole = (state: RootState) => state.user.me?.role
export const selectUserOperatorId = (state: RootState) => state.user.me?.operatorId
export const selectUserLocationId = (state: RootState) => state.user.me?.sites[0]?.stripeLocationId
// @ts-ignore
// export const selectRehydrated = (state: RootState) => state._persist.rehydrated

export default userSlice.reducer
