import React, { useCallback, useState } from 'react'
import useInterval from '@use-it/interval'

import { Alert, AlertTitle } from '@mui/material'
import { Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Button from 'lib/uikit/Button'

import packageInfo from '../../package.json'
import { useTranslation } from 'react-i18next'

const convertVersionToNumber = (version: string = ''): number => {
  return parseInt(version.replace(/\./g, ''), 10)
}

// const REJECT_ATTEMPTS = 3

function NewVersionUpdate() {
  const { t } = useTranslation('main')
  const [appVersion, setAppVersion] = useState<any>()
  // const [rejectCounter, setRejectCounter] = useState(0)

  useInterval(() => {
    return fetch('https://versions.api.weareome.com')
      .then(res => res.json())
      .then(({ webApp }) => {
        setAppVersion(webApp?.currentVersion)
      })
      .catch((e: any) => {
        console.error('Versions Checking Error:', e)
        // setRejectCounter(c => c + 1)
      })
  }, /* rejectCounter >= REJECT_ATTEMPTS ? null : */60 * 1000)

  const reload = useCallback(() => {
    window.location.reload()
  }, [])

  const handleClose = useCallback(() => {
    setAppVersion(null)
  }, [])

  if (!appVersion || convertVersionToNumber(appVersion) <= convertVersionToNumber(packageInfo.version)) return null

  return (
    <Dialog
      open={!!appVersion}
      onClose={handleClose}
      PaperComponent={({ children }) => <div>{children}</div>}
    >
      <Alert
        variant='outlined'
        severity='info'
        style={{ backgroundColor: 'white', padding: '20px 30px' }}
      >
        <AlertTitle style={{ paddingLeft: '8px', display: 'flex', gap: 20 }}>
          <div>{t('messages.new_version_of_app_is_available')}</div>
          <IconButton style={{ margin: '-12px' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </AlertTitle>
        <Button color='primary' onClick={reload}>
          {t('buttons.reload')}
        </Button>
      </Alert>
    </Dialog>
  )
}

export default NewVersionUpdate
