import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { createGenerateClassName, makeStyles, StylesProvider } from '@mui/styles'
import { SnackbarProvider, useSnackbar } from 'notistack'
import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'

// CSS
import 'styles/main.css'

// Apollo client
import { getApolloClient } from 'api/apollo'
import { useAppDispatch } from 'app/hooks'

// Theme
import theme from 'styles/theme'

// Auth Utils
import RefreshToken from 'components/RefreshToken'
import NewVersionUpdate from 'components/NewVersionUpdate'

const isProduction = process.env.REACT_APP_ENV === 'production'

const useStyles = makeStyles({
  snackbarContainer: {
    overflowWrap: 'anywhere'
  }
})

const Content = ({ children }: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const client = React.useMemo(() => getApolloClient({
    setError: err => enqueueSnackbar(err, { variant: 'error' }),
    dispatch,
    // TODO: add on ws reconnect here?
    onReconnect: async () => {
      console.log('onReconnect')
      const res = await client.refetchQueries({
        include: 'active'
      })
      console.log('onReconnect done =', res)
    },
  }), [enqueueSnackbar, dispatch])

  return (
    <ApolloProvider client={client}>
      <Route
        render={({ location }: any) => {
          return (
            <>
              {isProduction && <NewVersionUpdate />}
              {!['/login'].includes(location.pathname) && <RefreshToken />}
            </>
          )
        }}
      />
      {children}
    </ApolloProvider>
  )
}

const Layout = ({ children }: any) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      maxSnack={3}
      dense={true}
      classes={{ containerRoot: classes.snackbarContainer }}
    >
      <Router>
        <Content>{children}</Content>
      </Router>
    </SnackbarProvider>
  )
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'ome'
})

const App = ({ children }: any) => {
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'staging') {
      return
    }
    let link = document.querySelector('link[rel~=\'icon\']')
    if (!link) {
      link = document.createElement('link')
      // @ts-ignore
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    // @ts-ignore
    link.href = `${process.env.PUBLIC_URL}/favicon-second.ico`
  }, [])

  return (
    <React.Suspense fallback='...loading'>
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>{children}</Layout>
          </ThemeProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </React.Suspense>
  )
}

export default !process.env.REACT_APP_IS_LOCAL
  ? Sentry.withErrorBoundary(App, { fallback: <p>An error has occurred!</p> })
  : App
