import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from 'pages/App'
import { store/*, persistor*/ } from 'app/store'

import * as serviceWorker from './serviceWorker'
// import { bootIntercom, loadIntercom } from './lib/intercom'
// import { getUser } from './services/auth'

import packageInfo from '../package.json'

// loadIntercom()
// bootIntercom(getUser())

if (process.env.REACT_APP_SIMULATE_NATIVE) {
  (window as any).native = {
    printReceipts: (receiptJSON: any) => {
      console.log('receiptJSON=', JSON.parse(receiptJSON))
    },
  }
}

// https://sentry.io/hosted-kitchens/react/getting-started/javascript-react/
Sentry.init({
  environment: process.env.REACT_APP_ENV || 'development',
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  release: packageInfo.version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

Sentry.setTag('wrapperVer', (window as any)?.native?.getWrapperVersion?.() || 'n/a')

function importBuildTarget() {
  if (process.env.REACT_APP_IS_EMBED === 'true') {
    return import('pages/Embed')
  } else {
    return import('pages/Routes')
  }
}

importBuildTarget().then(({ default: Environment }) =>
  ReactDOM.render(
    <Provider store={store}>
      {/*<PersistGate persistor={persistor}>*/}
        <App>
          <Environment />
        </App>
      {/*</PersistGate>*/}
    </Provider>,
    document.getElementById('root')
  )
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
