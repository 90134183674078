import React, { useEffect, useMemo } from 'react'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import debounce from 'lodash.debounce'

// @ts-ignore
// tslint:disable-next-line:no-empty
export const Button = ({ onClick = () => {}, ...props }: Omit<ButtonProps, 'onClick'> & { onClick?: (event: React.MouseEvent<HTMLButtonElement>, currentTarget: HTMLButtonElement) => void }) => {
  const debouncedClickHandler = useMemo(() => debounce(onClick, 300, {
    'leading': true,
    'trailing': false,
  }), [onClick])

  useEffect(() => {
    return () => {
      debouncedClickHandler.cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MuiButton
      {...props}
      onClick={(e) => {
        e.persist()
        // @ts-ignore
        debouncedClickHandler(e, e.currentTarget)
      }}
    />
  )
}

export default Button
