import { MenuItemOption } from './menuItem'
import { RestaurantType } from './restaurants'
import { PromoCode } from './promo-code'

// DeliveryPlatformName
export enum DeliveryPlatformName {
  DELIVERECT,
  JUSTEAT,
  UBEREAT,
  DELIVEROO,
  GLOVO,
  TEVALIS,
}

// Order Status
export enum OrderStatus {
  NEW = 'NEW',
  PREPARING = 'PREPARING',
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  PICKED_UP = 'SHIPPING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED'
}

// Refund Reason
export enum RefundReason {
  CANCELLED_BY_RESTAURANT,
  MISSING_PRODUCTS,
  WRONG_PRODUCTS,
  BAD_QUALITY,
  DELIVERY_FAILED,
  OTHER,
}

// PosProvider
export enum PosProvider {
  TEVALIS,
}

// TODO:
export type User = any

export interface Reviews {
  id: string
  restaurantId: string
  deliveryPlatformType: DeliveryPlatformName
  time: string
  rating: number
  maxRating: number
  text: string
  customerName: string
  platformReviewId: string
  orderId: string
}

export interface OrderItem {
  restaurantId?: string | null
  itemId: string
  uniqueId: number
  itemName: string
  itemCover: string
  qty: number
  price: number
  total: number
  notes?: string | null
  checkOptions?: string | null
  cover?: string | null
  options: MenuItemOption[]
}

export interface OrderStatuses {
  id: string
  orderId?: string | null
  userId?: string | null
  comments?: string | null
  status: string
  dateTime?: string | null
  createdAt: string
  updatedAt: string
  user?: User | null
}

export interface BoughtMenuItemOption {
  id: string
  optionId: string
  name: string
  price?: number | null
  qty?: number | null
  tax?: number | null
}

export interface BoughtMenuItem {
  id: string
  itemId: string
  restaurantId?: string | null
  name: string
  cover: string
  price: number
  qty: number
  notes?: string | null
  tax?: number | null
  containerDepositAmount?: number | null
  boughtMenuItemOptions: BoughtMenuItemOption[]
}

export interface PromoCodeUsage {
  id: string
  promoCode: PromoCode
  user?: User | null
  order: Order
}

export interface OrderAddress {
  id?: string | null
  address?: string | null
  unit?: string | null
  lat?: number | null
  lng?: number | null
  phone?: string | null
  name?: string | null
  original?: string | null
}

export interface Order {
  id: string
  restaurantId?: string | null
  userId?: string | null
  items: OrderItem[]
  address?: OrderAddress | null
  status: OrderStatus
  picture?: string | null
  prevStatus: OrderStatus
  paymentIntent?: string | null
  cardFingerprint?: string | null
  platformCustomerHash?: string | null
  reordering: boolean
  platformId?: string | null
  platformSourceOrderId?: string | null
  platformSourceOrderDisplayId?: string | null
  vromoId?: string | null
  trackUrl?: string | null
  source: string
  refunded: boolean
  subtotal: number
  vat: number
  serviceFee: number
  deliveryFee: number
  total: number
  discount: number
  platformGross: number
  createdAt: string
  updatedAt: string
  paidDate?: string | null
  refundedDate?: string | null
  startPreparingDate?: string | null
  estimatedReadinessDate?: string | null
  platformExpectedPickupTime?: string | null
  preparedDate?: string | null
  pickupDate?: string | null
  completedDate?: string | null
  orderNotes?: string | null
  deliveryMethod: string
  sourcePlatform: string
  PaymentMethod: string
  courierName?: string | null
  courierPhone?: string | null
  refundedAmount: number
  refundReason?: RefundReason | null
  posProvider?: PosProvider | null
  posOrderId?: string | null
  canCancel: boolean
  canUpdateEstimatedReadinessDate: boolean
  orderStatuses: OrderStatuses[]
  boughtMenuItems: BoughtMenuItem[]
  promoCodeUsage?: PromoCodeUsage | null
  reviews?: Reviews[] | null
  user?: User | null
  restaurant: RestaurantType
  room?: string | null
  shortId: string
}

export type OrderEntityType = Omit<Order, 'restaurant'> & {
  restaurant: string
}
