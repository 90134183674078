import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit'
// import { setupListeners } from '@reduxjs/toolkit/query'
/*
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
*/

import userReducer from 'slices/user'
import uiReducer from 'slices/ui'
import apiReducer from 'slices/api'

/*
const persistConfig = {
  key: 'root',
  storage
}
*/
export const rootReducers = combineReducers({
  ui: uiReducer,
  api: apiReducer,
  user: userReducer,
})
// const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
  reducer: rootReducers,
})
/*
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})
*/

// export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// setupListeners(store.dispatch)
